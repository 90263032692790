export default class Utilities {
    hasClass(element, className) {
        return ` ${element.className} `.indexOf(` ${className} `) > -1;
    }

    scrollTo(element) {
        if (element !== null) {
            let offset = element.offsetTop;
            const siteHeader = document.querySelector('.site-header');
            if (siteHeader !== null) {
                offset -= siteHeader.offsetHeight;
            }

            window.scrollTo({
                behavior: 'smooth',
                top: offset,
            });
        }
    }

    scrollToNoHeader(element) {
        if (element !== null) {
            const offset = element.offsetTop;

            window.scrollTo({
                behavior: 'smooth',
                top: offset,
            });
        }
    }

    getCookie(name) {
        var dc = document.cookie;
        var prefix = name + '=';
        var begin = dc.indexOf('; ' + prefix);
        if (begin == -1) {
            begin = dc.indexOf(prefix);
            if (begin != 0) return null;
        } else {
            begin += 2;
            var end = document.cookie.indexOf(';', begin);
            if (end == -1) {
                end = dc.length;
            }
        }
        return decodeURI(dc.substring(begin + prefix.length, end));
    }

    throttle(fn, wait) {
        var time = Date.now();
        return function() {
            if (time + wait - Date.now() < 0) {
                fn();
                time = Date.now();
            }
        };
    }
}
