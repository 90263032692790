import '../images';
import '../svgs';

import './modules';
import './components';
import { FontLoader } from './tools';

const fonts = new FontLoader([
  { name: 'Poppins', weight: 900 },
  { name: 'Poppins', weight: 600 },
  { name: 'Poppins', weight: 400 },
]);

fonts.load();
