const siteHeader = document.querySelector('.site-header');
const headerTop = siteHeader.offsetTop;
function fixedNavigation() {
    if (window.scrollY > headerTop) {
        siteHeader.classList.add('site-header--fixed');
    } else {
        siteHeader.classList.remove('site-header--fixed');
    }
}

window.addEventListener('scroll', fixedNavigation);
