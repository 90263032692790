import { tns } from 'tiny-slider/src/tiny-slider';

const sliders = document.querySelectorAll('[data-behaviour="content-slider"]');

if (sliders.length > 0) {
    sliders.forEach(slider => {
        const nav = slider.parentElement.querySelector('.tns-nav');
        const controls = slider.parentElement.querySelector('.tns-controls');

        tns({
            container: slider,
            center: false,
            loop: false,
            slideBy: 1,
            nav: true,
            navPosition: 'bottom',
            navContainer: null !== nav ? nav : false,
            autoplay: false,
            speed: 400,
            autoplayButtonOutput: false,
            mouseDrag: true,
            controlsText: [
                '<svg xmlns="http://www.w3.org/2000/svg" width="10.177" height="16.112" viewBox="0 0 10.177 16.112">\n' +
                    '  <path d="M753.387,779.684l-7,7,7,7" transform="translate(-744.27 -778.623)" fill="none" stroke="#fff" stroke-width="3"/>\n' +
                    '</svg>',
                '<svg xmlns="http://www.w3.org/2000/svg" width="10.177" height="16.112" viewBox="0 0 10.177 16.112">\n' +
                    '  <path d="M746.391,779.684l7,7-7,7" transform="translate(-745.331 -778.623)" fill="none" stroke="#fff" stroke-width="3"/>\n' +
                    '</svg>\n',
            ],
            controlsPosition: 'bottom',
            controlsContainer: null !== controls ? controls : false,
            responsive: {
                0: {
                    items: slider.dataset.itemsMobile,
                    gutter: 10,
                },
                640: {
                    items: slider.dataset.itemsTablet,
                    gutter: slider.dataset.itemsGutter,
                },
                1040: {
                    items: slider.dataset.itemsDesktop,
                    gutter: slider.dataset.itemsGutter,
                },
            },
            onInit: () => {
                if (null !== controls) {
                    let prev = controls.querySelector('[data-controls="prev"]');
                    if (prev) {
                        prev.setAttribute('type', 'button');
                    }

                    let next = controls.querySelector('[data-controls="next"]');
                    if (next) {
                        next.setAttribute('type', 'button');
                    }
                }
            },
        });
    });
}
