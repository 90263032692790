const hamburger = document.querySelectorAll(
    '[data-behaviour="hamburger-menu"]',
);
const header = document.querySelector('.site-header');
const searchButton = document.querySelectorAll(
    '[data-behaviour="show-search"]',
);
const searchBanner = document.querySelector('.site-header__search');
let menu = document.querySelector('.site-menu');

// get height of header after onscroll event changes it.
let height;
function outputsize() {
    height = header.offsetHeight;
}
outputsize();

// Avaialable on all modern browsers: http://caniuse.com/#feat=resizeobserver
new ResizeObserver(outputsize).observe(header);

hamburger.forEach(item => {
    item.addEventListener('click', e => {
        e.preventDefault();
        const target = e.currentTarget;
        menu = document.querySelector('.site-menu');
        const lockScroll = document.querySelector('body');
        const navigation = document.querySelector('.navigation');

        const subMenuToggle = document.querySelectorAll('.child-menu-toggle');
        const subMenu = document.querySelector(
            '.navigation > .menu-item-has-children .child-menu',
        );
        const subMenuItem = document.querySelector(
            '.navigation > .menu-item-has-children .child-menu .menu-item',
        );

        subMenuToggle.forEach(item => {
            item.addEventListener('click', e => {
                e.preventDefault();
                const target = e.currentTarget;
                const parentItem = target.closest('.menu-item');

                navigation.classList.toggle('show-child-menu');
                target.classList.toggle('child-menu-active');
                parentItem.classList.toggle('child-menu-active');
            });
        });

        // Closing the menu if the search is opened
        searchButton.forEach(search => {
            search.addEventListener('click', event => {
                event.preventDefault();
                target.classList.remove('is-active');
                lockScroll.classList.remove('lock-scroll');
                menu.classList.remove('site-menu--show');
            });
        });

        menu.setAttribute('style', `top: calc(${height}px - 1px)`);
        target.classList.toggle('is-active');
        lockScroll.classList.toggle('lock-scroll');
        menu.classList.toggle('site-menu--show');
    });
});

searchButton.forEach(item => {
    item.addEventListener('click', e => {
        e.preventDefault();

        // Close search on menu event
        hamburger.forEach(nav => {
            nav.addEventListener('click', event => {
                event.preventDefault();
                item.classList.remove('active');
                searchBanner.classList.remove('active');
            });
        });

        item.classList.toggle('active');
        searchBanner.classList.toggle('active');
        searchBanner.setAttribute('style', `top: ${height}px`);
    });
});
