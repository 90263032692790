import Utilities from './utilities';
const utilities = new Utilities();

const popup = document.querySelector('[data-behaviour="site-popup"]');
const popupClose = document.querySelector(
    '[data-behaviour="site-popup-close"]',
);
const sitePopupCookie = utilities.getCookie('popupCookie');

if (popup) {
    function sitePopup(e) {
        if (popup && !sitePopupCookie) {
            document.body.classList.add('is-scroll-locked');
            popup.classList.remove('is-hidden');

            var data = {
                cookie: true,
            };

            var request = new XMLHttpRequest();
            request.open('POST', '/wp-json/noon/v1/set_cookie', true);
            request.setRequestHeader('Content-Type', 'application/json');
            request.send(JSON.stringify(data));
        }
    }

    popupClose.addEventListener('click', () => {
        document.body.classList.remove('is-scroll-locked');
        popup.classList.add('is-hidden');
    });

    setTimeout(sitePopup, 7000);
}
